/* omUIapi css - Version 1.05 June 11 2019 */
* {
  margin: 0px;
  padding: 0px;
}
body {
  font: 12px Arial, Helvetica, sans-serif; /* Myriad Pro */
  color: #a7a6a6;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  --prompt-left: 130px;
  --prompt-narrow-left: 90px;
}

/*------------- Header and Footer css ---------------------------------------------------------------------------*/
.clear {
  clear: both;
  margin: 0px;
  padding: 0px;
}

a {
  color: #fff;
  text-decoration: underline;
}

  a:hover {
    text-decoration: none;
  }

menuhref {
  padding: 0;
  margin: 0px 0px 0px 1px;
}
#Search {
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 34px;
  height: 24px;
  width: 160px;
  resize: none;
}
.searchBtnImg {
  position: absolute;
  margin: 0;
  top: 3px;
  right: 10px;
  width: 21px;
  resize: both;
  height: 21px;
}
#Messages {
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
  background-color: gray;
}
.messageImage {
  position: absolute;
  right: -2px;
  top: 2px;
  width: 14px;
  resize: both;
  height: 14px;
}
.popupImage {
  position: absolute;
  right: -2px;
  top: 45px;
  width: 14px;
  resize: both;
  height: 14px;
}
popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0,0,0,.05);
}
#messageBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 98%;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 100%;
  max-width: 700px;
  bottom: 2px;
  background-color: black;
  text-decoration: none;
  text-align: left;
  color: #fff;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
}
.errorMessage {
  color: red; 
  font-size: 13px;
}
#PageTitle {
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
}
btnImage.resize {
  transform: scale(0.5, 0.5);
  width: 20px;
  height: 20vh;
}
menuImage {
  height: 42px;
  float: right;
  margin: 0px;
  display: block;
  background: url(images/Info2.png) left top no-repeat;
}

/*------------- Main Page ------------------------------------------*/
.pageMain {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background-color: black;
}
#mainCntr {
  position: absolute;
  top: 106px;
  bottom: 40px;
  left: .5%;
  width: 99%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
/*------------- HEADER -------------*/
#headerCntr {
  border: none;
  position: relative;
  left: 0px;
  right: 0;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 104px;
  /*resize: both;*/
  background: url(images/header_bg.jpg) top left no-repeat;
}
#headerLogo {
  border: none;
  position: relative;
  left: 2px;
  top: 2px;
  display: block;
  width: 198px;
  height: 49px;
  background: url(images/bcs1.jpg) top left no-repeat;
}
#headerTopLeft {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 35%;
  height: 57px;
}
#headerBotLeft {
  border: none;
  position: absolute;
  left: 0px;
  top: 58px;
  width: 50%;
  height: 35px;
}
#headerBotLeft h2 {
  padding-top: 2px;
  padding-left: 30px;
  font-size: 24px;
  color: #fff;
  font-weight: normal;
  float: left;
}
#headerTopMid {
  border: none;
  position: relative;
  left: 35%;
  top: 0px;
  width: 34%;
  max-width: 700px;
  height: 57px;
}
#headerTopRight {
  border: none;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 31%;
  height: 57px;
}
#headerBotRight {
  border: none; /*1px solid red;*/
  position: absolute;
  right: 0px;
  top: 58px;
  width: 40%;
  height: 35px;
}
#headerBotStrip {
  border: none;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 13px;
  background: url(images/strip_bg.jpg) top left repeat;
}
#headerSearch {
  border: none;
  position: absolute;
  right: 0px;
  top: 2px;
  width: 210px;
  height: 35px;
}
#clockDiv {
  border: none; /*1px solid red; */
  position: absolute;
  right: 15px;
  top: 30px;
  width: 44px;
  height: 20px;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
}
#loginDiv {
  border: none;
  position: absolute;
  right: 10px;
  top: 2px;
  width: 130px;
  height: 20px;
}
.loginImage {
  position: relative;
  padding-left: 6px;
  bottom: 0px;
  width: 20px;
  resize: both;
  height: 20px;
}
.loginImageSB {
  position: relative;
  padding-left: 6px;
  bottom: 18px;
  width: 20px;
  resize: both;
  height: 20px;
}
.loginImageBig {
  position: relative;
  padding-right: 12px;
  top: 0px;
  width: 40px;
  resize: both;
  height: 40px;
}
.headImage {
  position: relative;
  padding-left: -40px;
  top: 43px;
  width: 80px;
  resize: both;
}
.topicImage {
  position: relative;
  padding-left: 0px;
  top: 0px;
  width: 60px;
  resize: both;
}
#statusDiv {
  border: none;
  position: absolute;
  left: 1px;
  top: 2px;
  width: 70px;
  height: 20px;
}
.statusImage {
  position: absolute;
  left: 8px;
  top: 0px;
  width: 20px;
  resize: both;
  height: 20px;
}
#logonName {
  border: none;
  position: absolute;
  left: 67px;
  top: 2px;
  width: 270px;
  height: 20px;
}
#functionDiv {
  border: none;
  position: absolute;
  right: 0px;
  bottom: 6px;
  width: 130px;
  height: 20px;
}
.functionImage {
  padding-left: 8px;
  top: 0px;
  width: 25px;
  resize: both;
  height: 25px;
}
/*------------- MENU -------------*/
#menuCntr {
  position: absolute;
  width: 570px;
  height: 28px;
  bottom: 0;
  right: 0px;
}
  #menuCntr ul {
    margin: 0px;
    padding: 0;
  }
  #menuCntr li {
    padding-left: 1px;
    list-style: none;
    float: left;
  }
    #menuCntr li a {
      text-decoration: none;
      text-align: center;
      width: 89px;
      height: 30px;
      display: block;
      color: #fff;
      line-height: 30px;
      font-family: Arial;
      font-size: 18px;
      background: url(images/menu_bg.jpg) left top no-repeat;
    }
  #menuCntr li a:hover {
    color: #333333;
  }
/* ### FOOTER MENU ### */
/* ### FOOTER CONTAINER ### */
#footerCntr {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: url(images/welcome_bg.jpg) center top repeat;
}

#footTopStrip {
  border: none;
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 5px;
  background: url(images/strip_bg.jpg) top left repeat;
}
.footermenu {
  position: absolute;
  left: 2px;
  bottom: 10px;
  width: 520px;
  overflow: hidden;
}
  .footermenu ul li {
    padding: 0px 18px;
    list-style: none;
    float: left;
    background: url(images/footer_line.jpg) left 4px no-repeat;
  }
  .footermenu ul li a {
    color: #fff;
    font-size: 12px;
    text-decoration: none;
  }
  .footermenu ul li a:hover {
    text-decoration: underline;
  }
  .footermenu ul li.first {
    padding-left: 0px;
    background: none;
  }
  .footermenu p {
    padding-top: 14px;
    float: left;
    color: #fff;
    font-size: 12px;
  }
  .footermenu p a {
    color: #fff;
    text-decoration: none;
  }
  .footermenu p a:hover {
    text-decoration: underline;
  }

#footerRight {
  border: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 35%;
  height: 35px;
}
/* ### TWITTER BOX ### */
.twitterbox {
  border: none;
  position: absolute;
  right: 10px;
  bottom: 7px;
  width: 110px;
  height: 20px;
}
/* ### TWITTER BOX ### */
.linkbox {
  border: none;
  position: absolute;
  right: 200px;
  bottom: 7px;
  width: 110px;
  height: 20px;
}

/*------------- End of - Header and Footer css ---------------------------------------------------------------------------*/


/*------------- Edit Page css ---------------------------------------------------------------------------*/

#editTopLeft {
  border: none; /*solid 4px green; */
  position: absolute;
  left: 0px;
  top: 3px;
  width: 35%;
  height: 45%;
  background-color: white;
}
#editTopRight {
  border: none; /*solid 4px blue; */
  position: absolute;
  right: 0px;
  top: 3px;
  width: 64%;
  height: 45%;
}

#editBottom {
  border: none;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 53%;
}
.editInside {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 2px;
  bottom: 1px;
  left: 10px;
  right: 10px;
  background-color: white;
}
.editInsideTab {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
}
/*-----------------------------------------------------------*/
.editTab {
  border: none; /*solid 4px red; */
  position: absolute;
  left: 0px;
  top: 35px; /* Room for tab*/
  bottom: 1px;
  left: 5px;
  right: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
}
.editTabLeft {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 50%;
  background-color: white;
}
.editTabLeftWide {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 69%;
  background-color: white;
}
.editTabRight {
  border: none; /*solid 1px green;  */
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 50%;
  background-color: white;
}
.editTabRightNarrow {
  border: none; /*solid 1px green;  */
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 31%;
  background-color: white;
}
.editField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 30px;
}
.editTAField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 65px;
}
.editFieldLabel {
  font-size: small;
  left: 0;     
  color: black;  
}
.editFieldLabelLeft {
  font-size: small;
  right: 0;
  color: black;
}
/*-------- Edit Fields -------------------*/
.editInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 65%;
  height: 21px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editShortInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 21px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editNumInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 21px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editNumShortInput {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 50px;
  height: 21px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editDDPos { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  margin-top: -20px;
  height: 28px;
  width: 260px;
}
.editInputDD { /* Inside editDDPos */
  /*border: solid 1px blue;*/
  position:absolute;
  left: 0px;
  margin-top: 1px;
  color: #2b00ff;
  width: 240px;
  background-color: honeydew;
}
.editDateInput { /* Inside editDDPos */
  position: absolute;
  left: 0px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editInputWide {
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  height: 21px;
  width: 80%;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editTAInput {
  border: solid 1px blue;
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew
}
.editTAInputWide {
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 80%;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew
}
.editTAInputWideLong {
  position: absolute;
  left: var(--prompt-left);
  height: 140px;
  right: 4px;
  width: 80%;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew
}
/*------------- Narrow --------------------------------------------*/

.editNrwNumShortInput {
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  width: 50px;
  height: 21px;
  margin-top: 1px;
  color: #2b00ff;
  background-color: honeydew;
}
.editNrwDDPos { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  margin-top: -30px;
  height: 28px;
}
/*------------- End of - Edit Page css ---------------------------------------------------------------------------*/
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /*background-color: rgb(0,0,0);*/
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
/*---------- Splitter --------------------*/
.pane-content {
  padding: 0 10px;
}

h3 {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
#divC {
  position: absolute;
  border: none; /*solid 3px black; */
  top: 0px;
  margin: -10px 0px 10px 0px;
  height: 90%;
  width: 90%;
}
#chartSingle {
  border: solid 3px red;
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 10px;
  right: 10px;
  background-color: red;
}
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* Template css - Get rid of what is not used specifically - March 8, 2019           */
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* ### BANNER CONTAINER ### */
#bannerCntr {
  position: relative;
  top: 0;
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  height: 366px;
  background: url(images/banner_bg.jpg) left top no-repeat;
}

.bannerTextBox {
  padding-top: 104px;
  padding-left: 50px;
  float: left;
  width: 450px;
  overflow: hidden;
}
  .bannerTextBox p {
    font-family: Arial;
    color: #fff;
    font-size: 28px;
  }

/* ### TEXT BOX ### */
.textBox {
  padding-top: 124px;
  float: left;
  width: 475px;
  overflow: hidden;
}

/* ### BANNER BOX ### */
.bannerBox {
  padding: 50px 0px 0px 25px;
  float: left;
  width: 428px;
  overflow: hidden;
}

/* ### HEADING BOX ### */
.headingBox {
  width: 98%;
  overflow: hidden;
  height: 176px;
}

  .headingBox img {
    padding-top: 15px;
    float: right;
  }

/* ### CONTENT CONTAINER ### */
#contentCntr {
  overflow: hidden;
  width: 98%;
  margin: 0 auto;
}

/*--------------------------------------------------------------------------------------------------------------------------------*/
/* Original ContractAccess css            */
/*--------------------------------------------------------------------------------------------------------------------------------*/
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Input-Half {
  width: 200px;
  color: #2b00ff;
}
.Input-Full {
  width: 340px;
  color: #2b00ff;
}
.ButtonLeft {
  text-align: left;
  width: 20px;
  height: 20px;
}
.ButtonImg {
  text-align: center;
  margin-top: 3px;
  margin-left: 3px;
  background-color: #000000;
  border: 0px;
  width: 20px;
  height: 20px;
}
.ButtonRight {
  text-align: right;
}
.Header1 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
  min-width: 200px;
}
.Label1 {
  text-align: right;
  color: #e0aa14;
  font-weight: bold;
}
.Label2 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
}
.Data1 {
  text-align: center;
  color: black;
  font-weight: bold;
}
.Display1 {
  text-align: left;
  max-width: 180px; 
  color: #2b00ff;
}
.Owner {
  text-align: right;
  color: #38b540;
  font-weight: bold;
}
/* override telerik default theme values*/
/*  */
.k-header > a.k-link {
  color: #e0aa14;
  font-size: 16px
}
.k-button {
  background-color: red
}
.k-button > .k-primary {
  color: red
}
.k-primary > .k-button > .k-grid-edit-command {
  background-color: red
}
.k-button > .k-grid-save-command {
  background-color: red
}
.k-button > .k-grid-cancel-command {
  background-color: red
}
.k-grid > td.k-state-selected, tr.k-state-selected > td {
  color: black;
  background-color: green;
}
/* end of telerik overrides */
h4 {
  color: #3e7ae0;
  font-size: calc(16px);
}
h5 {
  color: #eb0725;
  font-size: calc(10px + 1vmin);
}
td {
  min-width: 70px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
