/* ### GENERAL ### */
* { margin: 0px; padding: 0px; }

/* ### GLOBAL CLASSES ### */
.clear { clear: both; margin:0px; padding:0px; }

a{ color:#6db51c; text-decoration:underline;}
a:hover{ text-decoration:none;}

/* ### WRAPPER ### */
#wrapper { position: relative; display: block; width: 100%; min-height: 100%; }

	/* ### CONTENT CONTAINER ### */
	#contentCntr { overflow: hidden; width: 100%; margin-top: 2px; position: absolute;}
	#contentCntr .center{ width:980px; overflow: hidden; margin: 0 auto;}
	#contentCntr.background{ overflow: hidden; background: url(./images/sub/background_bg.jpg) repeat-x #FFFFFF;}
	               
		/* ### HEADING BOX ### */
		.headingBox {width:100%; height: 50px; background: url(./images/sub/heading_bg.jpg) center top repeat; }
		.heading{ width:980px; margin: 0 auto;}
		.heading h2 { padding-top:0; font-size: 48px; color: #0e8597; font-family: Arial; font-weight: normal; } 
    
		/* ### LEFT CONTAINER ### */
		#leftCntr{ width: 20%; float:left}
		
			/* ### STARTED BOX ### */
			.startedBox{padding:11px 16px 11px 24px; width: 253px; margin-top:39px; overflow:hidden; background: url(./images/sub/standard_bg.jpg) left top no-repeat;}
			.startedBox h2{ padding-bottom: 15px; width:175px; font-size: 30px; font-weight:normal; font-family:Arial;  color: #2b2a2a; background:url(./images/sub/heading_bor.jpg) left bottom no-repeat;}
			.startedBox p{ padding-top:5px; color: #6e6e6e; line-height: 20px;}
			.startedBox img{ padding-top: 10px; float: right; background: no-repeat;}
			
		/* ### RIGHT CONTAINER ### */
		#rightCntr{ width:63%; height:95%; float: right; margin-top: -24px;} /*border: 5px solid green;*/
			/* ### ABOUT BOX ### */
			.aboutBox{ padding-top:3px; width:652px; overflow:hidden;}
			.aboutBox h3{ padding: 12px 0px 0px 62px; font-weight:normal; font-size: 20px; font-family:Arial; color: #2b2a2a; background:url(./images/sub/about_icon.jpg) left top no-repeat;}
			.aboutBox h3 span{ padding-bottom:14px; display:block; background:url(./images/sub/p_border.jpg) bottom repeat-x;}
			.aboutBox p{ padding-top:26px; color: #575757; line-height: 20px;}
			.aboutBox .first{ padding-top:19px; display:block; color: #575757; font-style:italic; line-height: 20px;}
			.aboutBox a{ padding-left:67px; font-size: 12px; text-decoration: none; color: #FFFFFF; line-height:33px; display:block; font-style: italic; background:url(./images/sub/more_button1.png) left top no-repeat;}
			.aboutBox a:hover{ text-decoration: underline;}
			.aboutBox img{ margin:36px 27px 0px 0px; border: solid 6px #c1c1c1; float:left}
			/* ### MOTIVE BOX ### */
			.motiveBox{ padding:60px 0px 70px 0px; float:left; width:294px; overflow:hidden;}
			.motiveBox h3{padding: 13px 0px 20px 82px; font-size: 20px; font-family:Arial; color: #2b2a2a;  font-weight:normal; background:url(./images/sub/about_icon1.jpg) left top no-repeat;}
			.motiveBox h3 span{ padding-top:10px; font-style:italic; font-family:Arial, Helvetica, sans-serif; font-size:12px;display:block; }
			.motiveBox .icon {background:url(./images/sub/about_icon2.jpg) left top no-repeat;}
			.motiveBox.lasst{ float:right;}
			.motiveBox p{ padding-top:22px; color: #575757; line-height: 20px;}
			.motiveBox .border{background:url(./images/sub/p_border.jpg) top repeat-x;}
			/* ### TEXT BOX ### */
			.textBox{ padding-top:3px; top:0px; width:98%; overflow:hidden; position:relative;}                                                                                                
			.textBox h3{ padding: 24px 0px 5px 62px; font-weight:normal; font-size: 20px; font-family:Arial; color: #2b2a2a; background:url(./images/sub/service_icon1.jpg) left top no-repeat;} 
			.textBox h4{ padding: 7px 0px 5px 82px; font-weight:normal; font-size: 30px; font-family:Arial; color: #2b2a2a; }
			.textBox h3 span{ padding-bottom:10px; display:block; background:url(./images/sub/p_border.jpg) bottom repeat-x;}
			.textBox p{ padding-top:6px; color: #575757; line-height: 16px;}               
			.textBox .icon0{padding: 12px 0px 8px 62px;}
			.textBox .icon1{background:url(./images/sub/portfolio_icon.jpg) left no-repeat;}    
			.textBox .icon2{padding: 12px 0px 8px 62px; width:20px; resize:both; background:url(./images/sub/contact.png)  left top no-repeat;} 
			.textBox .icon3{padding: 12px 0px 8px 62px; background:url(./images/sub/participant.jpg) left top no-repeat;}    
			.textBox .first{ padding-top:10px;}
			
			/* ### SERVECE BOX ### */
			.serviceBox1{ padding:40px 0px 60px 0px; width:652px; overflow:hidden;}
			.serviceBox1 h3{ font-weight:normal; font-size: 20px; font-family:Arial; color: #2b2a2a; background:url(./images/sub/p_border.jpg) left bottom repeat-x; }
			.serviceBox1 h3 span{ padding:10px 0px 18px 0px; font-size:12px; color:#575757; font-family:Arial, Helvetica, sans-serif; display:block; }
			.serviceBox1 p{ padding-top:126px; color: #575757; line-height: 20px;}
			.serviceBox1 a{ margin:17px 0px 0px 0px; width: 174px; padding-left: 68px; font-size: 12px; float: left; text-decoration: none; color: #FFFFFF; display:block; line-height:31px; font-style: italic; background:url(./images/sub/more_button3.png) left top no-repeat;}
			.serviceBox1 a:hover{ text-decoration: underline;}
			.serviceBox1 .serviceBox2{ width:652px; overflow:hidden;}
			.serviceBox1 img{ padding:30px 58px 100px 19px; float:left;}
			
			/* ### PORTFOILIO BOX ### */

    /* ### PORTFOLIO BOX ### */
      .portfolioBox {padding-top: 5px; width: 556px; overflow: hidden; background: url(images/cross_bg.jpg) left top no-repeat; border-bottom: #333333 solid 1px;}
      .portfolioBox h2 { padding: 10px 0 10px 0; font-weight: normal; font-family: Arial; font-size: 24px; color: #fff; }
      .portfolioBox p { padding-bottom: 10px; width: 294px; font-size: 12px; color: #a7a6a6; line-height: 20px; float: right; }
      .portfolioBox img { border: #323232 solid 4px; float: left; }
      .portfolioBox a { margin-top: 10px; font-size: 12px; width: 80px; float: left; color: #000; display: block; line-height: 31px; text-decoration: none; text-align: center; background: url(images/more_button.jpg) left top no-repeat; }
        .portfolioBox a:hover { background: url(images/more_botton_h.jpg) left top no-repeat; }
      .portfolioBox .last { border: none; }

      /*--------------------------*/
			.portfolioBox{ padding:0px 0px 10px 0px; width:95%; overflow:hidden; background:#edf0f2; position:relative}
			.portfolioBox h3{ font-weight:normal; font-size: 18px; font-family:Arial; color: #2b2a2a; }
			.portfolioBox h3 span{ padding:6px 0px 8px 0px; font-size:12px; color:#575757; line-height: 16px;  font-family:Arial, Helvetica, sans-serif; display:block; }
			.portfolioBox p{ padding-top:-2px; color: #575757; line-height: 16px; width:70%;}
			.portfolioBox a{ margin:6px 0px 0px 15px; width: 102px; padding-left: 34px; font-size: 12px; float:left; text-decoration: none; color: #FFFFFF; display:block; line-height:22px; font-style: italic; background:url(./images/sub/more_button2.png) left top no-repeat;}
		  .portfolioBox a:hover{ text-decoration: underline;}
			.portfolioBox .portfolioBox1{ width:90%; overflow:hidden;}        
		  .portfolioBox.portfolioBox1.a:hover{ text-decoration: underline;}
			.portfolioBox img{ margin: 0px 2px 0px 0px; float:left; border: solid 4px #c1c1c1;}

			
			/* ### CONTACT BOX ### */
			.contactBox{ margin:0; width:90%; height:100%; overflow:hidden; }  /*border: 5px solid red;*/
			.contactBox input.field1{ padding: 2px 5px 2px 2px; display: inline-block; width:500px; height:21px; border:none; }
			.contactBox label{ width:150px; height: 41px; display: inline-block; font:14px Arial, Helvetica, sans-serif; color:#010000; text-align: left; vertical-align:top; font-weight:bold;}
			.contactBox label span{ display: block; font:12px Arial, Helvetica, sans-serif; color:#888787; text-align: left; vertical-align:top;}
			.contactBox textarea{ padding:2px 5px 2px 2px; width:500px; display: inline-block; height:279px; border:solid 1px #0073e6; }                                                            
			.contactBox input.button{ margin:18px 0px 0px 189px; float:left; background:url(./images/sub/more_button1.png) 0px 0px no-repeat; width:326px; height:37px; border:none; cursor:pointer; }  
			.contactBox button{ margin:18px 0px 0px 189px; float:left; background:url(./images/sub/more_button1.png) 0px 0px no-repeat; color: white; font-size: 20px; width:286px; height:37px; border:none; cursor:pointer; }
	
		/* ### TOP CONTAINER ### */
		#topCntr{ width: 100%; overflow: hidden; background: #ebeff2; position: relative;}
		#topCntrinner{ padding:16px 0px 15px 0px; width:100%; overflow: hidden; margin: 0 auto;}
		
		/* ### WELCOME BOX ### */
		.welcomeBox{ padding: 0 25px 0 25px; float: left; width:35%;}
		.welcomeBox h2{ padding: 22px 0px 19px 70px; font-weight:normal; font-size: 20px; font-family:Arial;  color: #2b2a2a; background:url('./images/bcs1.jpg') left top no-repeat; background-size: 100px;}
		.welcomeBox p{ padding:11px 0px 35px 0px; color: #575757; line-height: 20px;}
		.welcomeBox a{ padding-left:67px; font-size: 12px; text-decoration: none; color: #FFFFFF; line-height:33px; display:block; font-style: italic; background:url(./images/sub/more_button1.png) left top no-repeat;}
		.welcomeBox a:hover{ text-decoration: underline;} 
		
		/* ### WHAT BOX ### */
		.whatBox{ padding-right: 30px; float: left; width:28%;}
		.whatBox h2{ padding: 22px 0px 19px 70px; font-size: 20px; font-weight:normal; font-family:Arial;  color: #2b2a2a; background:url(./images/sub/question_mark_icon.png) left top no-repeat;}
		.whatBox p{ padding:11px 0px 35px 0px; color: #575757; line-height: 20px;}
		.whatBox a{ padding-left:67px; font-size: 12px; text-decoration: none; color: #FFFFFF; line-height:33px; display:block; font-style: italic; background:url(./images/sub/more_button1.png) left top no-repeat;}
		.whatBox a:hover{ text-decoration: underline;}
		
		/* ### SERVICE BOX ### */
		.serviceBox{float: left; width:28%;}
		.serviceBox h2{ padding: 22px 0px 19px 39px; font-size: 20px; font-weight:normal; font-family:Arial;  color: #2b2a2a; background:url(./images/sub/key_icon.png) left top no-repeat;}
		.serviceBox p{ padding:11px 0px 35px 0px; color: #575757; line-height: 20px;}
		.serviceBox a{ padding-left:67px; font-size: 12px; text-decoration: none; color: #FFFFFF; line-height:33px; display:block; font-style: italic; background:url(./images/sub/more_button1.png) left top no-repeat;}
		.serviceBox a:hover{ text-decoration: underline;}
		
		
		/* ### BOTTOM CONTAINER ### */
		#bottomCntr{ width: 100%; overflow: hidden; padding: 15px 5px 20px 5px; background: #f2f2f2; position: relative;}
		#bottomCntr .leftC { width: 35%; left: 20px; float: left;  position: absolute;} 		
		#bottomCntr .rightC { width: 62%; overflow: hidden; right: 0px; float: right;  position: absolute;} 
			
		/* ### GET BOX ### */
    .getBox{border-radius: 25px; padding:11px 16px 22px 24px; margin-bottom:24px; width: 90%; overflow:hidden; border: solid 2px #babfc2; background: #edf0f2; } 
		.getBox h2{ padding-bottom: 15px; width:275px; font-size: 30px; font-weight:normal; font-family:Arial; color: #2b2a2a; background:url(./images/sub/heading_bor.jpg) left bottom no-repeat;}
		.getBox p{ padding-top:5px; color: #6e6e6e; line-height: 20px;}
		.getBox img{ padding-top: 10px; float: right; background: no-repeat;}
			
		/* ### OUR BLOG BOX ### */ 
    .ourblogBox{border-radius: 25px; padding:11px 16px 22px 24px; margin-bottom:24px; width: 90%; height:95%; overflow:hidden; border: solid 2px #babfc2; background: #edf0f2 } 
		.ourblogBox .top{ width: 90%; }
		.ourblogBox .bottom{ padding: 11px 14px 20px 24px; overflow:hidden; width: 100%;}
		.ourblogBox h2{ padding-left: 58px; font-size: 20px; line-height:50px; font-weight:normal; font-family:Arial;  color: #2b2a2a; background: url(./images/sub/our_h2_bg.jpg) left top no-repeat;}
		.ourblogBox p span{ padding:9px 0px 4px 0px; color: #575757; font-weight: bold; display:block;}
		.ourblogBox p{ padding-bottom:12px; width: 90%; overflow: hidden;color: #575757; line-height: 20px; background: url(./images/sub/p_border.jpg) left bottom repeat-x;}
		.ourblogBox a{ margin:15px 0px 0px 115px; width: 132px; padding-left: 48px; font-size: 12px; float:right; text-decoration: none; color: #FFFFFF; display:block; line-height:32px; font-style: italic; background:url(./images/sub/more_button3.png) left top no-repeat; } 
		.ourblogBox a:hover{ text-decoration: underline;}   
		.ourblogBox .last{ padding-top:10px; background:none; }

#LinkNoStyle { margin: 0; }
			
		/* ### COMPANY BOX ### */
		.companyBox{width:99%; overflow:hidden;  }                                                                                       
		.companyBox h2{ padding: 22px 0px 19px 0px; font-size: 20px; font-weight:normal; font-family:Arial; color: #2b2a2a;}	
		.companyBox h3{ padding: 22px 0px 19px 0px; left:75px; top:-70px; font-size: 20px; font-weight:normal; font-family:Arial; color: #2b2a2a; position:relative}	
    .companyBox p{ padding:14px 0px 42px 0px; color: #575757; font-size:12px; line-height: 20px;}
		.companyBox a{ padding-left:67px; font-size: 12px; text-decoration: none; color: #FFFFFF; line-height:33px; display:block; font-style: italic; background:url(./images/sub/more_button1.png) left top no-repeat;}
		.companyBox a:hover{ text-decoration: underline;}
		.companyBox .icon1{ padding: 12px 0px 19px 70px; background:url(./images/sub/avatar-icon.png) left top no-repeat; background-size: 55px;}
		.companyBox .icon2{ padding: 12px 0px 19px 72px; background:url(./images/sub/Smart-Contracts.png) left top no-repeat; background-size: 50px;}
		.companyBox .icon3{ padding: 12px 0px 19px 72px; background:url(./images/sub/Metamask.jpg) left top no-repeat;background-size: 55px;}
		.companyBox .icon4{ padding: 12px 0px 19px 72px; background:url(./images/sub/company_icon3.png) left top no-repeat; background-size: 55px;}  
		.companyBox .icon5{ padding: 12px 0px 19px 72px; background:url(./images/sub/company_icon2.png) left top no-repeat; background-size: 55px;}
		.companyBox .icon6{ padding: 12px 0px 19px 72px; background:url(./images/sub/ipfs.jpg) left top no-repeat; background-size: 55px;} 
		.companyBox .icon7{ padding: 12px 0px 19px 70px; background:url(./images/sub/workflow.jpg) left top no-repeat; background-size: 50px;}
		.companyBox .icon8{ padding: 12px 0px 19px 72px; background:url(./images/sub/company_icon1.png) left top no-repeat;}
		.companyBox1{ padding-right: 12px; width:43%; overflow:hidden; float:left;  }
		.companyBox2{ width:46%; overflow:hidden; float:left;  }
		.companyBox3{ padding: 25px 12px 0px 0px; width:43%; overflow:hidden; float:left;  }
		.companyBox4{ padding-top:25px; width:46%; overflow:hidden; float:left;  }     
		.companyBox5{ padding: 25px 12px 0px 0px; width:43%; overflow:hidden; float:left;  }
		.companyBox6{ padding-top:25px; width:46%; overflow:hidden; float:left;  }
		